import React from "react";
import analysis1 from "../components/images/analysis.jpg";
import datasecurity from "../components/images/datasecurity.jpg";
import Footer1 from "../footer1";
import steps from "../components/images/cloudzap_steps.png";
import image1 from "../components/images/svg/SVG1.jpg";
import image3 from "../components/images/svg/SVG2.jpg";
import image4 from "../components/images/svg/svg3.jpg";
import image5 from "../components/images/person-check.svg";
import image6 from "../components/images/cloud-check.svg"
import image7 from "../components/images/file-earmark-bar-graph.svg"
import image8 from "../components/images/download.svg"
import cardbg from "../components/images/blue_techy_background.jpg";
import 'bootstrap/dist/css/bootstrap.min.css';
import Counter from "../components/count"
import Cards from "../cards1";
import "../components/count.css";
import Pricings from "../components/pricecards";
import Works from "../components/works";
import "../components/navbar.css";
import Navbar from "../components/Navbar";
//import AOS from 'aos';
import "../components/features.css";
import 'aos/dist/aos.css';
import Animatedcards from "../components/animatedcard";
import v from "../components/images/vul.jpg";
export default function Home(props) {
    return (
        <div>
        <div className="background">
           
           <Navbar/>
            <br />
            <div className="container pt-3 mt-3 mt-sm-4 pt-md-4 mt-md-4 pt-sm-4 pt-lg-5 mt-lg-5">
                <div className="row w-100">
                    <div style={{width:'40vw', marginLeft:'3vw'}} className="col d-inline-flex justify-content-center align-items-center">
                        <div className="d-inline">
                            {/*<div className='display-4 mb-3'
                                style={{color:'#011B30', fontSize: '3vw',fontWeight: '700',letterSpacing: '0.05vh'}}>
                                Using Our</div>*/}
                            <div className="display-6 mb-4"
                                style= {{color:'#011B30', fontSize: '3vw',fontWeight: '750',letterSpacing: '0.05vh'}}>
                                Cloud Vulnerability Analysis Tool</div>
                            <div className="lead mb-4"
                                style= {{color:'#05467b', fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#222222', width: '40vw'}}>
                                Safeguarding your cloud infrastructure is paramount , Cloudzap is an innovative, easy to deploy complete AWS and Azure Cloud Security Posture Management                                     Tool. <br />
                                Any person with a little technical knowledge can easily setup the tool using few clicks. <br />
                                Cloudzap gives 360 degree holistic visibility of your AWS and Azure account and it's security landscape. <br />
                                Cloudzap provides platform agnostic reports & Opens seamlessly on any device.
                            </div>
                            
                      {/*--------<div className="lead mt-3 mt-sm-3 mt-md-3 mt-lg-5 mx-auto" style={{backgroundColor: '#011B30', color: 'white', height: '5vw', width: '10vw', fontSize: '1.5vw', fontWeight: '700', borderRadius: '1vh', display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'none'}}>
                      <a style={{color: 'white'}} href='/contactus'>Contact Us</a>
                       </div>-------------------------------*/}
                        
                           
                        </div>
                    </div>
                    <div className="col svg1 align-items-center justify-content-center">
                            <img src={v} style={{width:'38vw'}}/>
                    </div>
                </div>
            </div>
            <div className="container text-center p-4 mr-5">
                {/*---------------------------------------*/}
                <div class="row pt-5">
                    <div class="col mt-3 mb-3">
                    <Cards cardimg = {cardbg} cardtitle= "Visualise Cloud Inventory" cardtext="Cloudzap provides a full inventory report for your entire AWS and Azure account with in-depth details about
                                subscribed regions, number of resources with counts." />
                    </div>
                    <div class="col mt-3 mb-3">
                    <Cards cardimg = {cardbg} cardtitle= "IAM roles, accounts and services security evaluation"
                     cardtext="Cloudzap analyzes IAM roles and policies assigned to resources and provides suggestions as per list
                                privilege concept to safeguard accounts."/>
                    </div>
                    <div class="col mt-3 mb-3">
                    <Cards cardimg = {cardbg} cardtitle= "Cloud Insights (Public And Private Networks)" cardtext="Cloudzap provides detailed report about all public facing resources with resource type, resource count
                                and open ports."/>
                    </div>
                </div>
            </div>
            <div className="container text-center pt-5">
                <div className="row align-items-start">
                    <div className="col svg1 align-items-center justify-content-center">
                        <div>
                            <img src={analysis1}style={{width: '40vw'}} />
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>Key Features</div>
                            <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2 mb-lg-5 mb-md-3 mb-sm-2 mb-2" style={{fontSize: '1.5vw'}}>Cloudzap scans AWS and Azure accounts
                                for security visibility to identify possible vulnerabilities, and try to exploit those platform related
                                issues using various internal tools and custom scripts.</div>
                            <div className="row">
                                <div className="col mb-sm-3 mb-3">
                                    <div className="boxhover mx-auto border border-secondary border-1 w-100 p-1" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                       <em> Virtual Network Insights</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="boxhover mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>IAM and Services</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="boxhover mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Identify Vulnerabilities</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="boxhover mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Easy to Deploy</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                </div>
                                <div className="col mb-sm-3 mb-3">
                                    <div className="mx-auto border border-secondary border-1 w-100 p-1" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Cloud Services Inventory</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Network and Data Flow</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Identify Config Issues</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                    <div className="mx-auto border border-secondary border-1 w-100 p-1 mt-3" style={{backgroundColor: 'white',fontSize: '1.5vw'}}> 
                                        <em>Simple Reporting</em>
                                        <i class="bi bi-check-circle-fill pl-2"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br /><br /><br />
            <div className="container text-center">
                <div className="row align-items-start">
                    <div className="col">
                        <div>
                        <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>Other Features</div>  <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2" style={{fontSize: '1.5vw'}}>
                                <div style={{fontSize: '2vw', fontWeight: '400'}}>Network And Data Flow</div>
                                 Cloudzap deeply analyzes cloud infrastructure and generates outstanding network diagrams with the resource mapping capabilities.
                                <br />
                                <br />
                                    <div style={{fontSize: '2vw', fontWeight: '400'}}>Identify Config Issues</div>
                                Cloudzap refers to identifying issues in cloud configuration, it generates and
                                compares security audit report findings with best practices to identify the security GAP and also provide
                                remediation steps to fine-tune AWS and Azure infrastructure.
                            </div>
                        </div>
                    </div>
                    <div className="col svg1 align-items-center justify-content-center">
                        <div>
                            <img src={datasecurity} style={{width: '40vw'}} />
                        </div>
                    </div>
                </div>
            </div>
            <br /><br />
            <div className="wrapper text-center w-100 mb-5 mx-auto">
                <Animatedcards></Animatedcards>
            </div>
            <br/><br/>
            <div className="container text-center mb-5" style={{color: '#011B30'}}>
            <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>Licensing</div>
            </div>
            <div className="container " style={{backgroundColor: 'white', border: '0.1vw solid black', borderRadius: '1vw', fontSize: '1.2vw'}}>                
            <table className="table">
                    <thead style={{fontSize: '1.3vw'}}>
                        <tr>
                            <th scope="col">Sr. No.</th>
                            <th scope="col">Features</th>
                            <th scope="col">Cloudzap Licenses</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Cloud Security Posture Management</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Easy to deploy and easy to use (Setup in few clicks)</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Provide detailed assessment about public and private subnets</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Region wise Cloud Resources and Services Inventory</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Easy to find orphan resources</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Identify Vulnerability or Weaknesses in Cloud configuration </td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>IAM role analysis</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Easily access report from any device</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br/>
            <div className="wrapper w-35" style={{height: '40vw', display:'flex'}}>
                <div id="carouselExampleFade" className="align-items-center d-flex carousel slide carousel-dark mx-auto" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active px-5">
                            <div className="card w-100 mx-auto" style={{verticalAlign: 'middle', height:'100%', width: '100%', height: 'fit-content', color: '#011B30'}}>
                                <div className="card-body">
                                    <h2 className="card-title"></h2>
                                    <div className="display-4 pb-3" style={{fontSize: '3vw', fontWeight:'300'}}>Product Specific Enhancements</div>
                                    <h6 className="card-subtitle mb-2 text-muted"></h6>
                                   {/* <p className="card-text lead" style={{fontSize: '1.5vw'}}><i className="uil uil-check card__list-icon"></i>
                                        VM Appliance, Market Place Image, Docker Image and IaaC (For Easy Deployment)
                                    </p>*/}
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        SaaS Model with Pay as You Go and Yearly Commitment Model
                                    </p>

                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        STD, PRO, Enterprise and MSP Editions (SaaS)
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Multi-Cloud and Hybrid Cloud Support (Azure{/*, GCP */} and AWS)
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Access through any device (seamless experience across devices){/*Mobile App*/}
                                   </p>
                                    <p className="card-text lead mb-3" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Renewal Reminder {/*and
                                        Push*/ } and Email Notifications{/*for widespread 0 Day attacks */} </p>

                                </div>
                            </div>
                        </div>
                        {/* <div className="carousel-item">
                            <div className="card w-75 mx-auto" style={{verticalAlign: 'middle', width: '100%', height: 'fit-content', color: '#011B30'}}>
                                <div className="card-body">
                                    <h5 className="card-title"></h5>
                                    <div class="display-4 pb-3" style={{fontSize: '3vw', fontWeight:'300'}}>Access Specific Enhancements
                                    </div>
                                    <h6 className="card-subtitle mb-2 text-muted"></h6>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Access Control and GEO Restriction
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        MFA with Conditional Access and Third Party Auth Support (Google/Microsoft Account Auth.
                                        Integration)
                                    </p>

                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        For Admin : Last 5 Login User/ Date Time / IP Addresses/ Session Duration (Detailed Access Report)
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        For User : Last Login Date Time /IP Address
                                    </p>
                                    <p className="card-text lead m-3" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Role Based Access Control (To give granular access control to specific resources)
                                    </p>
                                </div>
                            </div>
                        </div>*/ }
                       
                        <div className="carousel-item">
                            <div className="card w-75 mx-auto" style={{verticalAlign: 'middle', width: '100%', height: 'fit-content', color: '#011B30'}}>
                                <div className="card-body">
                                    <h2 className="card-title"></h2>
                                    <div className="display-6" style={{fontSize: '3vw', fontWeight:'300'}}>Technical Features Enhancement
                                    </div>

                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Cloud Environment Compliance Audit Report and Remediation Steps (CIS/HIPPA/PCIDSS/SOC2)
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Provide Suggestion About Security Best Practices to maintain highly secure environment. (GAP
                                        Analysis)
                                    </p>

                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Cloud Environment and Resources Centralized Log Analysis and Anomaly Detection Dashboard
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Cloud Resource Utilization Monitoring {/*and AI Driven Cost Optimization*/}
                                    </p>
                                { /*   <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Source Code Management Integration for security & compliance checks at any stage of the development
                                        CI/CD pipeline (GitHub, BitBucket)

                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Micro Services Resources and Performance Monitoring
                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Report History with Security Posture Comparison feature

                                    </p>
                                    <p className="card-text lead" style={{fontSize: '1.5vw'}}> <i className="uil uil-check card__list-icon"></i>
                                        Plugins for SIEM and Securities Products to hookup logs and data with industries well known SIEM and
                                        Security products
                                    </p>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev"  type="button" data-bs-target="#carouselExampleFade"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <div className="container text-center">
            <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>Pricing</div>
                <Pricings></Pricings>
            </div>
            { /* Steps section*/}
            {/*<div className="container text-center">
                <div className="row align-items-start">
                    <div className="col">
                        <div>
                        <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2" style={{fontSize: '1.5vw'}}>
                       {/* <Works/>
                               
                    </div>
                        </div>
                    </div>
                       </div>
            </div>*/}
                    <div className="col svg1 align-items-center justify-content-center" >
                        <div>
                            <img src={steps} style={{width: '40vw'}} />
                        </div>
                    </div>
             
   {  /* Features Section starts here
   <section class="section section-services">
      <div className="container2" >
      <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>FEATURES</div>
        <h2 class="common-heading"></h2>
        <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2 mb-lg-5 mb-md-3 mb-sm-2 mb-2" style={{fontSize: '1.5vw'}}><p>Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!</p></div>
      </div>
      <div class="container grid grid-three-column">
        <div class="service-box">
        <ion-icon name="cloud-done-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>
          Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="pie-chart-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>
          Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="person-circle-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque
             eos recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="planet-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>
          Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="earth-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>
          Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>

        <div class="service-box">
        <ion-icon name="analytics-outline"class="service-icon"></ion-icon>
          <h3>ABC</h3>
          <p>
          Lorem ipsum dolor sit amet. Et dolorem dolorum quo distinctio quae sed minus veritatis qui soluta nihil. Aut dolores maxime quo voluptas cumque eos 
          recusandae veniam. Est dolores accusamus aut fuga sequi et illum velit et maxime beatae!
          </p>
        </div>
      </div>
    </section>*/}
            {/*<div className="wrapper text-center"
                style={{backgroundColor:'skyblue'}}>
                <br /><br />
                <div className="text-center" style={{fontSize: '35px', color: '#fcfeff', marginBottom: '10px', fontWeight: '700'}}><em>How It
                    Works?</em>
                </div>
                <div className="row mx-0">
                    <div className="col">
                        <div>
                            <img class="text-white" src= {image5} style={{width:'15vw', color: '$white'}}/>
                        </div>
                        <h3 className="text-center display-6 text-white">Login</h3>
                    </div>
                    <div className="col">
                        <div>
                            <img class="text-white" src= {image6} style={{width:'15vw', color: '$white'}}/>
                        </div>
                        <h3 className="text-center display-6 text-white">Provide cloud details</h3><br /><br />
                    </div>
                    <div className="col">
                        <div>
                            <img class="text-white" src= {image7} style={{width:'15vw', color: '$white'}}/>
                        </div><br />
                        <h3 className="text-center display-6 text-white">Generate report</h3><br /><br />
                    </div>
                    <div className="col">
                        <div>
                            <img class="text-white" src= {image8} style={{width:'15vw', color: '$white'}}/>
                        </div><br />
                        <h3 className="text-center display-6 text-white">Download report</h3><br /><br />
                    </div>
                </div>
            </div>*/}
            
            


     
            
            
           
            
            
            
                <div className="container mt-5" style={{width: '100%'}}>
                    <div className="row">
                        <div className="col-9">
                        </div>
                        <div className="text-center" style={{fontSize: '3vw'}}><em>
                            Looking to improve your performance and security?
                        </em></div>
                        <div className="text-center" style={{fontSize: '2.5vw'}}>It is Simple!
                        </div>
                        {/*-- designed by me... enjoy! -->*/}
                        <div className="wrapper mt-3" style={{display: 'flex', justifyContent: 'center'}}>
                            <a className="cta" href='/contactus' 
                                                style={{display: 'flex',
                                                padding: '10px 40px',
                                                textDecoration: 'none',
                                                fontFamily: 'Poppins, sans-serif',
                                                fontSize: '20px',
                                                color: 'black',
                                                background: 'skyblue',
                                                transition: '1s',
                                                boxShadow: '6px 6px 0 #010115',
                                                transform: 'skewX(-5deg)'}} >
                                <span>Work With Us</span>
                                </a>
                                <span>
                                    <svg width="30px" height="20px" viewBox="0 0 66 43" version="1.1" xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink">
                                        <g id="arrow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <path className="one"
                                                d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                                                fill="#FFFFFF"></path>
                                            <path className="two"
                                                d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                                                fill="#FFFFFF"></path>
                                            <path className="three"
                                                d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                                                fill="#FFFFFF"></path>
                                        </g>
                                    </svg>
                                </span>
                        </div>
                    </div>
            </div>
                <br />
                <br />
                
                    <Footer1/>
                        
                </div>
                </div>
     );
}