import React from "react";
import './animatedcard.css';

export default function Animatedcards(props) {
    return (
        <div>
            <div class="wrapper w-100">
                <div class="box d-flex justify-content-center">
                    <div className="box-front align-items-center d-flex container justify-content-center display-4 text-light" style={{ height: '25vw', fontSize: '3vw'}}>
                      Why CloudZap?
                    </div>
                    <div class="box-content align-items-center justify-content-center d-flex container" style={{fontSize: '1vw'}}>
                        <ul className="text-start justify-content-center w-100" style={{fontSize: '1.5vw'}}>
                            <div class="lead" style={{fontSize: '1.5vw', textAlign:'center',marginLeft:'200px'}}><i class="fas fa-cloud"></i> Cloudzap can be used on any system having Linux or Ubuntu and python environment</div>
                            
                            <div class="lead" style={{fontSize: '1.5vw', textAlign:'center',marginLeft:'200px'}}><i class="fas fa-cloud"></i> Hardware requirement is as low as 1 GB RAM and 1 vCPU</div>
                            <div class="lead" style={{fontSize: '1.5vw', textAlign:'center',marginLeft:'200px'}}><i class="fas fa-cloud"></i> Cloudzap requires Access key and Secret key of an AWS account user with administrative
                                privileges in order to perform scanning and identify vulnerabilities.</div>
                            <div class="lead" style={{fontSize: '1.5vw', textAlign:'center',marginLeft:'200px'}}><i class="fas fa-cloud"></i> Cloudzap requires Tenant ID and Account ID of an Azure account user with administrative
                                privileges in order to perform scanning and identify vulnerabilities.</div>
                            <div class="lead" style={{fontSize: '1.5vw', textAlign:'center',marginLeft:'200px'}}><i class="fas fa-cloud"></i> Easy to understand report in various formats that can be accessed through web browser
                                once scan is completed</div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}