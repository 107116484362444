//import demo1 from "../components/demo1.jpg";
import React,{ useState} from 'react';
import { Container } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from '../components/Navbar';
//npm install react-datepicker
import "./demo.css";
import Footer1 from '../footer1';
import Main from "../Main";
import "../Main.css";
import demo_booking from "../components/images/demo_booking.jpg";
function Reactdatepicker()
{
    const [selectesDate, setSelectedDate]= useState('');
   
    return( 
      
        <React.Fragment>
     <Navbar/>
        
        <Container>
        
        <div className="container text-center pt-5">
                <div className="row align-items-start">
                    <div className="col svg1 align-items-center justify-content-center">
                        <div>
                            <img src={demo_booking}style={{width: '70vw'}} />
                        </div>
                    </div>
                    <div className="col">
                        <div>
                            <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>Book a Demo</div>
                            <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2 mb-lg-5 mb-md-3 mb-sm-2 mb-2" style={{fontSize: '1.5vw'}}>
                                Book the demo for the <b>CloudZap tool</b> by filling the quick form below or you can mail us at info@clouservesystems.com</div>
                            <div className="row" style={{textAlign:'left'}}>
                                <div className="col mb-sm-3 mb-3">
                                   
                 <div className="row-m">
                             <div className="col-sm-10">
        
            <form className="from-container" onSubmit={setSelectedDate} action="https://api.web3forms.com/submit"
  method="POST">
                
                 <div className="row mb-4 ">
        { /*   <label name="date" htmlFor="date" className="col-sm-2 col-form-label" onclic> Select a Date for the Demo  </label>*/}{}
            <div className="col-sm-5 c">
            <input type="hidden" name="redirect" value="https://cloudzap.cloudservesystems.com/thanks"/>
                 <input type="hidden" name="access_key" value="83e1ce45-4085-46ae-982d-2c9ddb99d801"/>
            <input required  placeholder="Name"
                name ="Name"
                 autoComplete="off"/>
                 
                <input 
                required
                name ="Work Email"
                placeholder="Work Email"
                type="email"
                autoComplete="off"/>
                 <input 
                required
                name ="Company Name"
                placeholder="Company Name"
                type="text"
                autoComplete="off"/>
         {/* <b>  <label for="birthdaytime">Select the date and time for the Demo</label></b>
            <input type="datetime-local" id="date" name="date" required/>*/}
  
           
         {/* <DatePicker selected={ selectesDate}
            onChange={ date=>setSelectedDate(date)}
            dateFormat="dd-MM-yyyy"
            //minDate={ new Date()}
            //maxDate={ new Date()}
            //filterDate={ date=>date.getDay()!=6 && date.getDay()!=0}
           // isClearable
            showYearDropdown
     
    />  */}
           
            <button href="/thanks.html"type="submit" className="b1">Submit</button>   
                                
            </div>
            
            </div>

           
            </form>

            </div>
        </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
        </Container>
        <br/><br/><br/>
        <Footer1/>
        </React.Fragment>
    );
}

export default Reactdatepicker;
 /*import DatePicker from 'react-datepicker';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Navbar from "../components/Navbar";
import "./demo.css";
function Demo(){
    const[startDate , setStartDate] = useState(new Date());
    function onChangeDataHandler(value){
        setStartDate(value);
    }
        return(
            <>
             <Navbar/>
            <div className="demo">
                 
                <DatePicker
                selected ={startDate}
                onChange={onChangeDataHandler}
                
                />

            </div></>
            
        )
}
export default Demo;*/