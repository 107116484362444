import React from "react";
import './textcomp.css';

export default function TextComp(props) {
    return (
        <div >
        <div class="display-6 text-dark ">
          {/*  Phone:
            <a href="#" class="link ml-5"> <span>+91 76000 02540</span>
            </a> </div>*/}
        <div class="display-7 text-dark mt-5">
            Email-ID:
            <p className="email" style={{whiteSpace:'normal',fontSize:'15px',textAlign:'center'}}>info@cloudservesystems.com</p>
      
    </div>
    <br/>
    <br/>{/*<div class="display-6 text-dark mt-5 mr-5">
        <div>Address:</div>
    <a href="#" class="link w-75 ml-5 mt-3 mb-5">
        <span className="fs-3">A-103, Madhuram Tower, Opp .St Kabir School, Naranpura, Ahmedabad-380013, India</span></a>
</div>*/}
</div>
</div>
    );
}