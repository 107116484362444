import "./footer1.css";
import './components/navbar.css';
import im1 from "../src/components/images/CloudZap.jpg";
const Footer1 = () => {
  return (
    <div className="footer">
      <div className="top">
        <div><div className="logo"> 
         <img src={im1} alt="logo"/>
         </div><b>
         <h2 style={{fontWeight:'600'}}>CloudZap</h2>
          <p style={{fontWeight:'800'}}>CloudServe Systems Pvt Ltd</p></b>
        </div>
        <div>
          <p>
            {/*<a href=" https://www.linkedin.com/company/cloudserve-systems-pvt-ltd"><i class="fa-brands fa-linkedin"></i></a> */}
            <i style={{fontSize:'15px'}} class="fa-solid fa-phone">+91 76000 02540</i><br/>
            <i style={{fontSize:'15px',display:'flex'}} class="fa-solid fa-envelope"><p>info@cloudservesystems.com</p></i>
          </p>
        </div>
      </div>
      <div className="bottom">
        { /*<div>
          <h3 style={{textAlign:'start',fontWeight:'500'}}> Pages </h3>
          <a href="/home">Home</a>
          <a href="/partnerpage">Partners</a>
          <a href="/">Services</a>
          <a href="/demo">Contact Us</a>
          <a href="/contactus">Book a Demo</a>
        </div>*/}
      </div>
      <div className="Policy">
          <a href="/terms-of-use.html" target="_blank"><b>Terms of Use</b></a> &nbsp;&nbsp;
          <a href="/privacy-policy.html" target="_blank"><b>Privacy Policy</b></a>
      </div>
      <br/>
      <p><b>Address:</b> A-103, Madhuram Tower, Opp .St Kabir School, Naranpura, Ahmedabad-380013, India</p>
      <b><p>Copyright @2016 | Designed by CloudServe Systems Pvt Ltd</p></b> 
    </div>
  );
};
export default Footer1;