import React from "react";
import "../components/count.css";
import CountUp from 'react-countup';
import ScrollTrigger from "react-scroll-trigger";
import { useState } from "react";

export default function Counter(props){
    const [CounterOn , setCounterOn] =useState(false);
    return(

        <ScrollTrigger onEnter={()=>setCounterOn(true)} onExit={()=> setCounterOn(false)}>
             <div class="section section-work-data " >
                <div class="container3 grid grid-four-column"style={{textAlign:'center' ,alignItems:'center'}}>
                    <div>
                    <h2 class="counter-numbers">{CounterOn && <CountUp end={568} duration={1}/>}</h2>
                    <b> <p> Cloud Services</p></b>
                    </div>
                    <div>
                        <h2 class="counter-numbers">{CounterOn && <CountUp end={20} duration={1.5}/>}K</h2>
                        <b><p> Misconfigs</p></b>
                    </div>
                    
                    <div>
                        <h2 class="counter-numbers">{CounterOn && <CountUp end={158} duration={1}/>}+</h2>
                        <b><p> Cloud Providers</p></b>
                    </div>
                    
                    <div>
                        <h2 class="counter-numbers">{CounterOn && <CountUp end={1200} duration={1}/>}</h2>
                        <b><p> Reports Generated</p></b>
                    </div>
                    
                </div>
            </div>
        </ScrollTrigger>

    );

}