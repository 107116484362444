import './App.css';
import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import Home from './routes/homepage';
import ContactUs from './routes/contactus';
import Partner from './routes/partner';
import  Reactdatepicker from "./routes/demo";
import Thanks from "./routes/thanks"
function App() {
  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Routes>  
        <Route path="/" element={<Navigate to="/home" replace={true}/>} />
          <Route path="/home" element={<Home />}/>
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/partnerpage" element={<Partner/>}/>
          <Route path="/demo" element={< Reactdatepicker/>}/>
          <Route path="/thanks" element={< Thanks/>}/>
        </Routes>
        
        </BrowserRouter>
      
    </div>
  );
}

  export default App;
