import "../components/works.css"; 
import { useState } from "react";

function Works(){  
    const [cards] = useState([
        {
        title:'Step 1',
        text: 'Signup with your cloud account details',
        icon:"fa-solid fa-user",
        i1:"fa-solid fa-arrow-down"
        },
        { 
            title:'Step 2',
            text: 'Login with credentials',
            icon:"fa-solid fa-check-double",
            i1:"fa-solid fa-arrow-down"
            },
            {
                title:'Step 3',
                text: 'Collects details of services of your cloud account',
                icon:"fa-solid fa-circle-info",
                i1:"fa-solid fa-arrow-down"
                },
                {
                    title:'Step 4',
                    text: 'Identify security misconfigurations and risks',
                     icon:"fa-solid fa-bug",
                     i1:"fa-solid fa-arrow-down"
                    },
                    {
                        title:'Step 5',
                        text: 'Detailed report generation with suggestions',
                        icon:"fa-solid fa-square-poll-vertical"
                        }
    ])
return(
<div>
  <section>
<div className="container1">
<div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '500'}}>HOW IT WORKS?</div>
  <div className="c1">
      {
          cards.map((card,i) => (
              <div key={i} className="c2">
                 
                 <a> <i class={card.icon}></i></a> <h3>{card.title}
                  </h3>
                   <p>   {
                          card.text
                      }
                  </p>
                  <i class={card.i1}></i>
              </div>
          ))}
  </div>
</div> 
</section>
</div>
);
                    }
                    export default Works;



