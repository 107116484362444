import React from "react";
import './pricecards.css'

export default function Pricings(props){
    return(
      <section>
        <div>
        <div class="container-fluid ">
        <div class="container pt-5 pb-5">
          <div class="row">
            <div class="col-lg-3 col-md-12 mb-4">
              <div class="card card1 h-100">
                <div class="card-body">
                 
                  <h5 class="card-title">STD</h5>
                  <small class='text-muted'>single-user/year</small>
                  <br /><br />
                  <span class="h2">$1500</span>/year
                  <br /><br />
                  <ul>
                    <li>Upto 10 Instances</li>
                    <li>2 VPCs</li>
                    <li>2 DBs</li>
                    <li>2 Server Less</li>
                  </ul>
                </div>
    
                
              </div>
            </div>
            <div class="col-lg-3 col-md-12 mb-4">
              <div class="card card2 h-100">
                <div class="card-body">
                 
                  <h5 class="card-title">PRO</h5>
                  <small class='text-muted'>multi-user/year</small>
                  <br /><br />
                  <span class="h2">$5500</span>/year
                  <br /><br />
                  <ul>
                    <li>Upto 50 Instances</li>
                    <li>10 DBs</li>
                    <li>10 Server Less</li>
                  </ul>
                </div>
    
                
              </div>
            </div>
            <div class="col-lg-3 col-md-12 mb-4">
              <div class="card card3 h-100">
                <div class="card-body">
                 
                  <h5 class="card-title">ENT</h5>
                  <small class='text-muted'>multi-user/year</small>
                  <br /><br />
                  <span class="h2">$10000</span>/year
                  <br /><br />
                  <ul>
                    <li>50 to 100 Instances</li>
                    <li>20 DBs</li>
                    <li>25 Server Less</li>
                    
                  </ul>
                </div>
    
                
              </div>
            </div>
            <div class="col-lg-3 col-md-12 mb-4">
              <div class="card card4 h-100">
                <div class="card-body">
                 
                  <h5 class="card-title">MSP</h5>
                  <small class='text-muted'>multi-user/year</small>
                  <br /><br />
                  <span class="h2">$21000</span>/year
                  <br /><br />
                  <ul>
                    <li>Unlimited Access</li>
                    
                  </ul>
                </div>
    
                
              </div>
            </div>
          </div>    
        </div>
        </div>
        </div>
        </section>
    );
}