import Navbar from '../components/Navbar';
import "./demo.css";
import demo1 from "../components/images/demo1.jpg";
import Footer1 from '../footer1';
import thankyou from "../components/images/thankyou.jpg";
function Thanks() {
    return (
      <>
      <Navbar/>
                        <div className="container text-center pt-5" style={{marginTop:'50px',marginBottom:'60px'}}>
                        <div className="row align-items-center">
                            <div className="col svg1 align-items-center justify-content-center">
                                <div>
                                    <img src={thankyou} style={{width: '40vw' , alignContent:'center'}} />
                                </div>
                            </div>
                            <div className="col">
                                <div>
                                    <div className="display-2 m-2" style={{color: '#011B30',fontSize: '3vw', fontWeight: '900'}}>Thank you for reaching out to us!</div>
                                    <div className="lead mt-lg-5 mt-md-3 mt-sm-1 mt-2 mb-lg-5 mb-md-3 mb-sm-2 mb-2" style={{fontSize: '1.5vw' , fontWeight:'400'}}>
                                      We got your message and our team is on it , Expect to hear back from us soon!</div>
                                    <div className="row">
                                        <div className="col mb-sm-3 mb-3">
                                        
                        <div className="row-m">
                                    <div className="col-sm-10">

                       
                        </div>
                        </div>
                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <Footer1/>
                        </>
                        
);
}

export default Thanks;