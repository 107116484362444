import React from 'react';
import './cards.css';
import './cards1.css';
import Card from 'react-bootstrap/Card';

export default function Cards(props){
    return(
        <div>
        <Card style={{height:'100%'}} className="bg-dark text-white w-75 mx-auto">
            <Card.Img 
                src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0NDQ0NDQ0NDQ0NDQ0NDQ0NDQ8NDQ0NFREWFhURFRUYHSggGBolGxUVITEhJSk3Oi46Fx8zODM4NygtLisBCgoKDQ0NDg0NDysZFRkrKy0rKzc3KystNy0tKy0rKystNys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIARMAtwMBIgACEQEDEQH/xAAZAAEBAQEBAQAAAAAAAAAAAAACAQADBgX/xAAYEAEBAQEBAAAAAAAAAAAAAAAAARECEv/EABgBAQEBAQEAAAAAAAAAAAAAAAABAwIF/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oADAMBAAIRAxEAPwD68hYshyPSeIGNjpiYAYnl0xsBzxLHSwbFRzxLy6WJYDlYNjpiYDnYFjrYNgOVg2OtgWA52DY62DgrlYNjrYFgOdiYdiYK52MeMK+9IciQsRyjFjYKONhY2COdgunUCxQcSng2CBYNh1KDnYNhpYDnYGOtCxQKJ2JYDnQsdbAsFc6hVEExlYV97mFiyE5BxjxMAWIbFQaFOpioCUrEsALArpYNgOdg07BsVAo2HUAMGnRooUadGg52DTsHBRxVZFeh5hYvMXHJg2Jh4mAI9Og2KjmxWJYAUaeDYqBRp0aAUadGxUChXSjQCjYaWA52DY6YPUFcxsOiAoWMK9LI2LFxm6HGsLGoOdiWHjYqOdgV1oWKgYNjoNio5WJh2DYDnRrpYFVAsGnRoBYh0QCjTo0VzsGw7EwAZVFelkVZGZu0xKViYIOJTSqlc6Nh2CRAqU7BUc+oFdKNVHOwbHSwKI52DYdg2KCNh4lBzo10sDoAolYgo4y4wPT42KzNoLYWIAphYlECwadGxUCjTSqjnYFjpQqoFgV0oWCBRpJVASkNAaFOjYDnYLpQoqIqA9SzRWbREpIAotSiDRp0KqDRpVKqBQp0KqBRp0aI50bDo1QRpJQc+kwqNAaFOhQFFrCvURWismiNVRQai1BBoU6NVBo0qNVBoU6HSoFGl0FEGpSo1QRpUKA1CqANc66dOdAWVhXp4yRWbRUZqCCtQQaJUaqDUq0aqDQpUaqBQp0KIlGrUqgUaYUBZkBK506FBGVhXpYyRWbRUZAapWqURKNWjVcjRpUKolGrRqoNClRog0atSqJQpUaCUKVEBo1aNBmZhXpIqRWbRmrJREqVkqolGrRpESjVo1UGjSo1QaFKhVRKNq0aCUaqUBtGraIJaNWjQVE1hXpYoxWbtUrJQRKo1USjStCrESjVqVUGjVo0BoUqNVBo1alBKNq0aA1GqAlClRoqMzA9JFGLrN2uo2oDDWSqiUbVqVUSjWo2qiUatGgNGlRVBqVaNoDRq0aCVFo0Eo1aNFZkUHoooyrGbtUbUERFqVRKNWjVRKNWpVQaNKhQShSo1UG0aVCglGrUAaNWpRUo1aNBmRhXoooRWbok1GEapWqVUSpWqVRBq0aqJRq1KA0KVGqg0aVCglFalAaNKjRUtFaIMyVhXoJV1z1dcKepqamgVqJamiNala0bVGtG1rRtVGtGro0Eo1aNEShStGqJRq0aKg2qNoJRWjRW1GZB95mZyqtEYGRmVEosyiUazCINZgAazCJRrMqjRqMA2pWYBosyKNZmFf/Z"
                alt="Card image" />
            <Card.ImgOverlay>
            <Card.Title style={{fontSize:'2vw'}} class="mt-lg-3 mt-md-2 mt-sm-0 mt-0">{props.cardtitle}</Card.Title>
            <Card.Text style={{fontSize:'1.3vw'}} class="lead align-middle mt-lg-3 mt-md-3 mt-sm-0">
             {props.cardtext}
            </Card.Text>
            </Card.ImgOverlay>
        </Card>
        </div>
    );
}