import React from "react";
import Navbar from "../components/Navbar"
import TextComp from "../components/textcomp";
import '../components/textcomp.css';
import Footer1 from "../footer1";
import "../Main.css";
import Main from "../Main";
import c1 from "../components/images/contact_1.jpg";
export default function ContactUs(props) {
    return (
        <div>
            <div className="background">
                <Navbar/>
                <Main
                 cName="main"
                 mainimg={c1}
                  title="Contact Us"
                                   />
                                   <div className="wrapper mt-lg-5 mt-md-3 mt-sm-1 mt-2" style={{ backgroundColor: '#ffffff' }}>
                    <div className="pt-lg-5 pt-md-3 pt-sm-1 pt-2 mt-lg-5 mt-md-3 mt-sm-1 mt-2">
                        <TextComp></TextComp>
                    </div>
                </div>
                 
                { /*     <div className="pt-lg-5 pt-md-3 pt-sm-1 pt-2 mt-lg-5 mt-md-3 mt-sm-1 mt-2 display-1">
                    <b>Reach Us!</b>
    </div>
              
             <div>
                    <div style={{ padding: '5vw' }}>
                        <div class="text-center display-6 text-dark mb-5" style={{ width: '100%' }} ><b><em>Office Address</em></b></div><iframe style={{ borderRadius: '0.25rem' }} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58740.52847703473!2d72.54961!3d23.050083!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb99cd517e6b524fe!2sSt.Kabir%20School!5e0!3m2!1sen!2sin!4v1657799887418!5m2!1sen!2sin" width="100%" height="800" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
    */} 
                {/*  <div className="display-3">
                    Reach Us on Internet!
                </div>
             <div className="wrapper pt-lg-5 pt-md-3 pt-sm-1 pt-2 mt-lg-5 mt-md-3 mt-sm-1 mt-2" style={{ backgroundColor: '#484890' }}>
                    <div className="pt-lg-5 pt-md-3 pt-sm-1 pt-2">
                        <i class="bi bi-linkedin fa-10x text-light"></i>
                        <div class="display-6 text-light mt-lg-5 mt-md-3 mt-sm-1 mt-2">
                            <a href="https://www.linkedin.com/company/cloudserve-systems-pvt-ltd?trk=similar-pages" class="link">
                                <span>LinkedIn</span>
                            </a>
                        </div>
                    </div>
    </div>**/}
                {/*--------Footer--------------------*/}
             
                    <Footer1/>
                </div>
            </div>

    );
}