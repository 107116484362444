/*
CloudZap by Cloud Serve Pvt. Ltd
*/
export const MenuItems= [ // required to store all the links of the navbar.
    {
        //below all are arrays created 
        title : "Home",
        url : "/home",
        cName : "nav-links",

    },
    {
        title : "Partners",
        url : "/partnerpage",
        cName : "nav-links",

    },
    /*{
        title : "Book a Demo",
        url : "/demo",
        cName : "nav-links-mobile", //here we have a button redirecting to the contact us page

    },*/

];