import "./Main.css";
//making everthing dynamic using props and using the variable name with that so that all tha pages an be changes dynamicaaly
function Main(props) {
  return (
    <>
      <div className={props.cName}>
        <img alt="mainimg" src={props.mainimg} />
        <div className="main-text">
          <h1> {props.title}</h1>
          <h2>{props.title1}</h2>
          <p> {props.text} </p>
          <a href={props.url} className={props.btnClass}>
            {props.buttonText}
          </a>
        </div>
      </div>
    </>
  );
}
export default Main;
