import "./mission.css";

import Partnership from "../src/components/images/Partnership.png";
const Mission = () => {
  return (
    <div className="mission">
      
      <br/>
      <div className="first-mission">
        <div className="mis-text">
        <div className="display-6 mb-4" style= {{marginLeft:'50px',textAlign:'start',color:'#011B30', fontSize: '3vw',fontWeight: '750',letterSpacing: '0.05vh'}}>
                        CloudZap Partnership</div>
                                <div className="lead mb-4"
                                style= {{marginLeft:'50px',color:'#05467b', fontSize: '1.5vw',fontWeight: '500', fontStyle:'normal', lineHeight:'2vw', color: '#222222', width: '40vw'}}>
                               
          <p style={{textAlign:'start'}}>
          We partner with proven technology leaders with best-of-breed 
          digital business transformation tools to provide world class, cloud-ready, secure, agile 
          and modern infrastructure application and data solutions.
          </p>
          </div>
         
        </div>
        {/*<div className="image">
          <img
            alt="img"
            src= {partner_1}
          />
          
        </div>*/}
        <div className="col svg1 align-items-center justify-content-center">
                        <div>
                            <img src={Partnership} style={{width: '35vw' , borderRadius:'10px'}} />
                        </div>
                    </div>
      </div>
      <br/><br/><br/>
    </div>
  );
};
export default Mission;
